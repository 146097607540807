import { createApp, defineAsyncComponent } from 'vue';

// Store
import store from './store/store';
import TheHeader from './components/parts/TheHeader';
//import Select2 from 'v-select2-component';
// Брал отсюда https://github.com/godbasin/vue-select2

const Menu = defineAsyncComponent(() =>
  import("./components/Menu.vue")
);
const mainScreen = defineAsyncComponent(() =>
  import("./components/MainScreen.vue")
);
const Accounting = defineAsyncComponent(() =>
  import("./components/Accounting.vue")
);
const Expenditure = defineAsyncComponent(() =>
  import("./components/Expenditure.vue")
);
const ParishArticles = defineAsyncComponent(() =>
  import("./components/ParishArticles.vue")
);
const Settings = defineAsyncComponent(() =>
  import("./components/Settings.vue")
);
const Statistics = defineAsyncComponent(() =>
  import("./components/Statistics.vue")
);

import App from './App.vue'

const app = createApp(App);

app.use(store);

app.component('TheHeader', TheHeader);
app.component('Menu', Menu);
app.component('MainScreen', mainScreen);
app.component('Accounting', Accounting);
app.component('Expenditure', Expenditure);
app.component('ParishArticles', ParishArticles);
app.component('Settings', Settings);
app.component('Statistics', Statistics);
//app.component('Select2', Select2);

app.mount('#app');

