import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      activeComponent: 'MainScreen',
      menuIsShown: false,
      comesCategories: new Object(),
      expansesCategories: new Object(),
      popular_consumptions: null,
      popular_comings: null,
    }
  },
  mutations: {
    setActiveComponent(state, payload) {
      state.activeComponent = payload;
    },
    setMenuIsShown(state, payload) {
      state.menuIsShown = payload;
    },
    setComesCategories(state, payload) {
      state.comesCategories = payload;
    },
    setExpansesCategories(state, payload) {
      state.expansesCategories = payload;
    },
    setPopularConsumptions(state, payload) {
      state.popular_consumptions = payload;
    },
    setPopularComings(state, payload) {
      state.popular_comings = payload;
    }
  },
  actions: {
    async queryComesCategories(context) {
      const response = await fetch(`/php/comes/getCategories.php`);

      let answerData = await response.json();

      if (answerData.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answerData.message);
        return false;
      }

      let result = {};
      const categories = answerData.categories;
      const items = answerData.items;

      categories.forEach(category => {
        result[category.categoryId] = {
          id: category.categoryId,
          name: category.categoryName,
          elements: [],
        };  
      });


      items.forEach(item => {
        result[item.categoryId].elements.push({
          id: item.itemId,
          name: item.itemName,
        });
      });
      context.commit('setComesCategories', result);
    },
    async queryExpansesCategories(context) {
      const response = await fetch(`/php/expenses/getCategories.php`);

      let answerData = await response.json();

      if (answerData.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answerData.message);
        return false;
      }

      let result = {};
      const categories = answerData.categories;
      const items = answerData.items;

      categories.forEach(category => {
        result[category.categoryId] = {
          id: category.categoryId,
          name: category.categoryName,
          elements: [],
        };  
      });


      items.forEach(item => {
        result[item.categoryId].elements.push({
          id: item.itemId,
          name: item.itemName,
        });
      });
      context.commit('setExpansesCategories', result);
    },
    async queryPopularConsumptions(context) {
      const response = await fetch(`/php/expenses/getItems.php`);

      let answerData = await response.json();

      if (answerData.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answerData.message);
        return false;
      }

      context.commit('setPopularConsumptions', answerData);
    },
    async queryPopularComings(context) {
      const response = await fetch(`/php/comes/getItems.php`);

      let answerData = await response.json();

      if (answerData.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answerData.message);
        return false;
      }

      context.commit('setPopularComings', answerData);
    },
  },
  getters: {
    getActiveComponent(state) {
      return state.activeComponent;
    },
    getMenuIsShown(state) {
      return state.menuIsShown;
    },
    getComesCategories(state) {
      return state.comesCategories;
    },
    getExpansesCategories(state) {
      return state.expansesCategories;
    }
  }
});