<template>
  <the-header></the-header>
  <keep-alive>
    <component :is="activeComponent"></component>
  </keep-alive>
</template>

<script>
import normilizeCSS from "normalize.css";

export default {
  data() {
    return {};
  },
  mounted() {
    // Получаем категории статей и сами статьи приходов
    this.$store.dispatch('queryComesCategories');
    // Получаем категории статей и сами статьи расходов
    this.$store.dispatch('queryExpansesCategories');
  },
  computed: {
    activeComponent() {
      return this.$store.getters["getActiveComponent"]
        ? this.$store.getters["getActiveComponent"]
        : "MainScreen";
    },
  },
  methods: {},
  modules: {
    normilizeCSS,
  },
};
</script>

<style lang = "scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body {
  font-family: "Roboto", sans-serif;
  background-color: #0B3144;
  padding: 30px;
  max-width: 640px;
  margin: 0 auto;
  color: white;
}
* {
  box-sizing: border-box;
  outline: unset;
}

.add_more_btn {
  font-size: 16px;
  color: #457993;
  width: 100%;
  text-align: center;
  padding: 14px;
  border: 1px dashed #457993;
  background: none;
  margin-bottom: 16px;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background:#103d53;
    color:  #74a4bd;
    border-color:  #74a4bd;
  }
}
.error {
  border: 1px solid red;
  color: rgb(255, 177, 177);
}



/* Inputs */
select {
  width: 100%;
  padding: 14px;
  margin-bottom: 16px;
  background: #457993;
  border: none;
  color: white;
  cursor: pointer;
  transition: .2s;
  > option:first-of-type {
      display: none;
    }
  &:hover {
    background: #5289a5;
  }
}

input {
  width: 100%;
  max-width: 100%;
  background: #457993;
  border: none;
  box-shadow: none;
  color: white;
  padding: 14px;
  cursor: pointer;
  transition: .2s;
  &::placeholder {
    color: white;
  }
  &:hover {
    background: #5289a5;
  }
  &[type=date] {
    &::-webkit-calendar-picker-indicator {
      
      opacity: 1;
      background: url('assets/img/icons/calendar.svg') no-repeat center;
      background-size: contain;
    }
  }
}

.common_input {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    max-width: 85%;
  }
}

.date_input {
  margin-bottom: 16px;
}

.consumption_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  
  input {
    max-width: 35%;
  }
}


input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
     z-index: 1;
 }

</style>
