<template>
  <header class="header">
    <a class="header_btn-toggle" @click="toggleMenu">
      <div class="header_btn-burger">
        <div></div>
        <div></div>
        <div></div>
      </div>
      Меню
    </a>
  </header>
</template>

<script>
export default {
  data() {
    return {
      prevComponent: null,
    };
  },
  computed: {
    menuIsShown() {
      return this.$store.getters["getMenuIsShown"];
    },
  },
  methods: {
    toggleMenu() {
      this.$store.commit("setMenuIsShown", !this.menuIsShown);
      if (this.menuIsShown) {
        this.prevComponent = this.$store.getters["getActiveComponent"];
        this.$store.commit("setActiveComponent", "Menu");
      } else {
        this.$store.commit("setActiveComponent", this.prevComponent);
      }
    },
  },
};
</script>

<style lang = "scss">
.header {
  background-color: #19516D;
  border-radius: 22px 22px 0px 0px;
  
  border-bottom: 1px solid #4183A4;
  margin-bottom: 13px;
}
.header_btn-toggle {
  display: flex;
  padding: 13px 26px 11px 26px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  align-items: center;
  text-transform: uppercase;
  border-radius: 22px 22px 0px 0px;
  color: white;
  cursor: pointer;
  transition: .2s;
  &:hover {
    background-color: #236180;
  }
  &:hover,
  &:active,
  &:focus {
    color: white;
  }
}
.header_btn-burger {
  margin-right: 10px;
  > div {
    height: 2px;
    width: 20px;
    margin-bottom: 5px;
    background-color: white;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>